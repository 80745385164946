<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Dashboard" icon="gauge" />

    <div class="dashContainer">
        <div class="row">
            <div class="col-12 mb-2">
                <fa icon="table" class="menu-icon" style="font-size: 18px"/> 

                <span class="align-top float-end d-none d-md-block">
                    <span @click="$router.push({ path: '/uploadDoc' })" class="text-primary isLink me-3">
                        <fa icon="folder-plus" class="me-2"/>Add folder
                    </span>

                    <span @click="$router.push({ path: '/doc/verify' })" class="text-primary isLink me-3">
                        <fa icon="check-double" class="me-2"/>Verify docs
                    </span>

                    <span @click="$router.push({ path: '/dashboard/report' })" class="text-primary isLink">
                        <fa icon="file-contract" class="me-2"/>Report
                    </span>
                </span>
            </div>
            
        </div>

        <div class="row">
            <div class="col-12 col-lg-9">
                <div class="row">
                    <div class="col-lg-4 d-grid">
                        <!-- Overview -->
                        <div class="border shadow-sm dashBox">

                            <div class="boxTitle">{{day}}, {{ today }}</div>

                            <div class="d-flex flex-wrap justify-content-between">
                                <span class="filter-pill-border">
                                    <span class="filter-item-pill" @click="filterOverview = 'today'" :class="filterOverview === 'today' ? 'active' : ''">TODAY</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'month'" :class="filterOverview === 'month' ? 'active' : ''">MONTH</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'year'" :class="filterOverview === 'year' ? 'active' : ''">YEAR</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'total'" :class="filterOverview === 'total' ? 'active' : ''">TOTAL</span>

                                    <!-- <span class="filter-item-pill" @click="filterOverview = 'today'" :class="filterOverview === 'today' ? 'active' : ''">Today</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'month'" :class="filterOverview === 'month' ? 'active' : ''">Month</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'year'" :class="filterOverview === 'year' ? 'active' : ''">Year</span>
                                    <div class="vr"></div>
                                    <span class="filter-item-pill" @click="filterOverview = 'total'" :class="filterOverview === 'total' ? 'active' : ''">Total</span> -->
                                </span>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="innerBox text-center">
                                        <div class="fw-bold">1,024</div>
                                        <div class="small">Folder</div>
                                    </div>
                                </div>
                                <div class="col px-0">
                                    <div class="innerBox text-center">
                                        <div class="fw-bold">288</div>
                                        <div class="small">Invited</div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="innerBox text-center">
                                        <div class="fw-bold">67</div>
                                        <div class="small">Signed</div>
                                    </div>
                                </div>
                            </div>
                            <!-- Overview End -->
                        </div>
                    </div>

                    <div class="col-lg-4 d-grid">
                        <!-- Workflow -->
                        <div class="border shadow-sm dashBox">
                            <div class="boxTitle">WORKFLOW</div>
                            <div class="innerBox text-center mb-2">
                                <div class="row">
                                    <div class="col text-center">
                                        <div class="fw-bold">2</div>
                                        <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc pending for me to sign">Pending</Popper></div>
                                    </div>
                                    <div class="col text-center">
                                        <div class="fw-bold">50</div>
                                        <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc approved by me">Approved</Popper></div>
                                    </div>
                                    <div class="col text-center">
                                        <div class="fw-bold">2</div>
                                        <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc rejected by me">Rejected</Popper></div>
                                    </div>
                                </div>
                            </div>

                            <div class="innerBox text-center mb-2">
                                <div class="row px-1">
                                    <div class="col text-center">
                                        <div class="fw-bold">65</div>
                                        <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc initiated by me">Initiated</Popper></div>
                                    </div>
                                    <div class="col text-center px-1">
                                        <div style="border: 1px solid grey; border-radius: 10px;">
                                            <div class="fw-bold">5</div>
                                            <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc that initiated by me still in progress">OnGoing</Popper></div>
                                        </div>
                                    </div>
                                    <div class="col text-center px-1">
                                        <div style="border: 1px solid grey; border-radius: 10px">
                                            <div class="fw-bold">60</div>
                                            <div class="small isTooltipsLabel"><Popper class="popperDark" arrow hover content="Total doc that initiated by me is completed">Completed</Popper></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Workflow End -->
                    </div>
                    <div class="col-lg-4 d-grid">
                        <!-- SSO -->
                        <div class="border shadow-sm dashBox">
                            <div>
                                <span class="boxTitle">SSO </span>
                                <span class="float-end small"><router-link to="/dashboard/sso">More</router-link></span>
                            </div>
                            <table class="table table-hover mb-0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <img alt="Vue logo" src="../../assets/signon_logo7.svg" class="align-top" style="height: 20px" />
                                            <fa icon="qrcode" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                            <div class="small text-grey fst-italic">last login 5 secs ago</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img alt="Vue logo" src="../../assets/logo_notis.png" class="align-top" style="height: 20px" />
                                            <fa icon="key" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                            <div class="small text-grey fst-italic">last login 12 days ago</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img alt="Vue logo" src="../../assets/logo_online_delivery.png" class="align-top" style="height: 20px" />
                                            <fa icon="qrcode" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                            <div class="small text-grey fst-italic">last login 12 days ago</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- SSO End -->
                    </div>
                    <div class="col-12">
                        <!-- OutStanding -->
                        <div class="border shadow-sm dashBox">
                            <div class="boxTitle">
                                <router-link to="/dashboard/outstanding">OUTSTANDING</router-link>
                                <span class="float-end isLink" @click="showOutFilter = !showOutFilter"><fa icon="filter" /></span>
                            </div>
                            <div v-if="showOutFilter" class="row">
                                <div class="col-lg-3 mb-2">
                                    <div class="input-group">
                                        <span class="input-group-text"><fa icon="search" /></span>
                                        <input type="text" v-model="filterDoc" class="form-control" placeholder="Document Name">
                                        <span v-if="filterDoc && filterDoc.length > 0" class="input-group-text clearSearch" @click="delfilterDoc">
                                            <fa icon="xmark" />
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-3 mb-2">
                                    <table>
                                        <tr>
                                            <td class="pe-2">From</td>
                                            <td><Datepicker v-model="dateFr" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-lg-3 mb-2">
                                    <table>
                                        <tr>
                                            <td class="pe-2">To</td>
                                            <td><Datepicker v-model="dateTo" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-lg-3">
                                    <table align="right">
                                        <tr>
                                            <td class="pe-2">Order</td>
                                            <td>
                                                <select class="form-select">
                                                    <option value="Date">Date ASC</option>
                                                    <option value="DateDesc">Date DESC</option>
                                                    <option value="Doc">Document ASC</option>
                                                    <option value="DocDesc">Document DESC</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div> 

                                <table class="table table-hover">
                                    <tbody>
                                        <tr v-for="(o, oIdx) in arrOut" :key="o">
                                            <td>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <router-link :to="'/project/' + o.projectId">
                                                            <div v-if="o.title" class="fw-bold mb-2">{{o.title}}</div>
                                                            <div v-else class="fw-bold mb-2">-</div>
                                                        </router-link>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <Popper class="popperDark" arrow hover :content="o.creator.userId">
                                                        {{o.creator.displayName}}
                                                        </Popper>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <span class="isTooltipsLabel greyLink">
                                                            <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(o.dateCreate, 'datetime')">
                                                                <span class="isTooltipsLabel text-grey small"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(o.dateCreate), new Date()) }}</i></span>
                                                            </Popper>
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-1 text-end">
                                                        <a @click="arrContent[oIdx] = !(arrContent[oIdx] === true)" data-bs-toggle="collapse" :href="'#content' + oIdx" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                            <fa icon="angle-up" v-if="arrContent[oIdx] === true" class="ms-1"/>
                                                            <fa icon="angle-down" v-else class="ms-1"/>
                                                        </a>
                                                    </div>

                                                    <div :id="'content' + oIdx" class="col-12 collapse">
                                                        <!-- Content -->
                                                        <div >
                                                            <span>
                                                                <span  
                                                                @click.prevent="router.push('/pdf/view/'+proj.projectId+'/'+doc.documentId)" 
                                                                class="d-inline-block text-truncate border rounded-pill me-1 px-2 py-1 docPill isLink">
                                                                    <fa icon="file-pdf" class="me-1" size="lg" />xxxx
                                                                </span>
                                                                <span class="px-2 py-1 d-inline-block text-truncate border rounded-pill">2+</span>
                                                            </span>
                                                        </div>
                                                        <!-- End of Content -->
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Outstanding End -->
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-3">
                <!-- Notification -->
                <div class="border shadow-sm dashBox">
                    <div class="boxTitle">
                        <router-link to="/dashboard/notification">NOTIFICATION</router-link>
                        <span class="float-end"><fa icon="trash" class="isLink" /></span>
                    </div>

                    <div class="dashBoxContent">
                        <table class="table table-hover" style="margin-bottom: 0px; padding-bottom: 0px;">
                            <tbody>
                                <tr v-for="(n, nIdx) in arrNotif" :key="n">
                                    <td @click="readNot(n.id, n.isRead, n)" class="isLink">
                                        
                                        <div class="fw-bold">
                                            
                                            <span v-if="n.isRead"><fa icon="envelope-open" class="me-2" /></span>
                                            <span v-else><fa icon="envelope" class="me-2" /></span> 

                                            <span v-if="n.action === 'ADD PROJECT SIGNEE'">Signee added</span>
                                            <span v-if="n.action === 'SEND PROJECT'">Invitation sent</span>
                                            <span v-if="n.action === 'ASSIGN DOCUMENT'">Document assigned</span>
                                            <span v-if="n.action === 'UPLOAD DOCUMENT'">Document uploaded</span>
                                            <span v-if="n.action === 'UPDATE DOCUMENT'">Document updated</span>
                                            <span v-if="n.action === 'UPDATE PROJECT'">Project updated</span>
                                            <span v-if="n.action === 'UPDATE PROJECT SIGNEE'">Signee updated</span>
                                            <span v-if="n.action === 'DOCUMENT SIGNED'">Document signed</span>
                                            <span v-if="n.action === 'DOCUMENT ALLSIGNED'">Document signed by all</span>
                                            <span v-if="n.action === 'DELETE PROJECT SIGNEE'">Signee deleted</span>
                                            <span v-if="n.action === 'DELETE DOCUMENT'">Document deleted</span>

                                            <span class="float-end"><fa icon="xmark" class="isLink" /></span>
                                        </div>
                                        <div>
                                            <span>{{n.description}}</span>
                                        </div>
                                        <div class="small text-grey fst-italic text-end">{{ func.getDateTimeDiff(func.convDateTimeFormat(n.dateCreate), new Date()) }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- Notification End -->
            </div>
        </div>

    </div>
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

export default {
    name: 'Dashboard',
    components: { Alert, TopNavigation, Popper, Avatar, Datepicker },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()
        // const { getDisplayName, getUserId } = useStore()

        const today = ref('')
        const day = ref('')
        const maxOut = ref(10) // Max oustanding records
        const maxNotif = ref(10) // Max SSO records

        const showOutFilter = ref(false)
        const filterDoc = ref('')
        const dateFr = ref(new Date())
        const dateTo = ref(new Date())

        const filterOverview = ref('today')

        const arrNotif = ref([])
        const arrOut = ref([])
        const arrContent = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const getNotification = () => {

            const p = {
                module: ['SIGNON'],
                orderBy: [
                    {
                        field: 'dateCreate',
                        order: 'desc'
                    }
                ],
                limit: "0, " + maxNotif.value
            }

            axios.post( '/report/notice', p
            ).then((res) => {

                if (res.data.status === 1) {
                   arrNotif.value = res.data.data
                } 
            })
            .catch((error) => {
                console.log('getNotif ERR', JSON.stringify(error))
            })
        }

        const getOutstanding = () => {
            const p = { 
                orderBy: [
                    {
                        field: "dateModify",
                        order: 'desc'
                    }
                ],
                limit: "0, " + maxOut.value
             }

            axios.post( '/signon/list', p)
                .then((res) => {
                    if (res.data.status === 1) {
                        arrOut.value = res.data.data
                    } 
                })
                .catch((error) => {
                    console.log('getOutstanding | FAILURE', JSON.stringify(error))
                })
        }

        const delfilterDoc = () => {
            arrOut.value = []
            filterDoc.value = ''
            getOutstanding()
        }

        const getToday = () => {

            let date = new Date()
            let d = date.getDate() + ''

            /* const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
            const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] */

            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Tthursday', 'Friday', 'Saturday']
            
            today.value = d + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
            day.value = days[date.getDay()]
        }


        onMounted(() => {

            func.clearPrevLocalStorage()

            getToday()
            
            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back " + store.getDisplayName.value + ", your last login was " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            getNotification()
            getOutstanding()
        })

        return { 
            alert, closeAlert, router, func, store, 
            today, day, maxNotif, maxOut, arrNotif, arrOut, arrContent, filterDoc, delfilterDoc, 
            dateFr, dateTo, showOutFilter, filterOverview, 
        }
    }
}
</script>

<style>

.dashContainer {
  /* width: 96%;  */
  width: 98%; 
  margin: 105px auto 20px auto;
}

.dashBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
}

.dashBoxContent {
    /* height: 770px;  */
    height: 100%; 
    overflow: auto;
    padding-right: 5px;
}

.innerBox {
    border-radius: 10px;
    padding: 10px;
    /* background-color: var(--accent-color); */
    background-color: var(--auth-box-bg);
    /* border: 1px solid var(--auth-box-bg); */
    /* border: 1px solid var(--accent-color); */
}

@media (max-width: 500px) {
    .dashContainer {
      width: 95%;
      margin: 105px auto 20px auto;
    }

    .dashBox {
      border-radius: 10px;
    }
}

.boxTitle {
    font-size: 12px;
    font-weight: 700 !important;
    margin-bottom: 0.5rem !important;
    box-sizing: border-box;
    color: #6E777E;
    line-height: var(--bs-body-line-height);
}
</style>